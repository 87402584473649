<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6" md="12">
        <b-card v-if="data" text-variant="center" class="card card-congratulations">
          <!-- images -->
          <b-img :src="require('@/assets/images/elements/decore-left.png')" class="congratulations-img-left" />
          <b-img :src="require('@/assets/images/elements/decore-right.png')" class="congratulations-img-right" />
          <!--/ images -->

          <b-avatar variant="primary" size="70" class="shadow mb-2" :src="data.avatar">
            <!-- <feather-icon size="28" icon="AwardIcon" /> -->
          </b-avatar>
          <h1 class="mb-1 mt-50 text-white">
            欢迎管理员
          </h1>
          <!-- <b-card-text class="m-auto w-75">
            活跃度 <strong>{{ data.saleToday }}次</strong>
          </b-card-text> -->
        </b-card>
      </b-col>
      <b-col lg="3" sm="6">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-${leftData.color}`"
              style="background-color: rgba(115, 103, 240, 0.12);" size="45">
              <feather-icon size="21" icon="GitPullRequestIcon" style="color:#7367f0" />
            </b-avatar>
            <div class="truncate">
              <!-- <h2 class="mb-25 font-weight-bolder">
                {{ leftData.room }}
              </h2> -->
              <span>路灯总数：{{ leftData.room }}</span>
            </div>
          </b-card-body>

          <vue-apex-charts type="area" height="100" width="100%" :options="leftData.areaChartOptions"
            :series="leftData.chartData" />
        </b-card>
      </b-col>

      <b-col lg="3" sm="6">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-${rightData.color}`"
              style="background-color: rgba(255, 159, 67, 0.12)" size="45">
              <feather-icon size="21" icon="RadioIcon" style="color:#ff9f43" />
            </b-avatar>
            <div class="truncate">
              <!-- <h2 class="mb-25 font-weight-bolder">
                {{ rightData.room }}
              </h2> -->
              <span>广播总数:{{ rightData.room }}</span>
            </div>
          </b-card-body>

          <vue-apex-charts type="area" height="100" width="100%" :options="rightData.areaChartOptions"
            :series="rightData.chartData" />
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">

          <b-col lg="6" md="3" cols="6">
            <b-card v-if="Ordersdata" body-class="pb-50">
              <h6>控制器使用情况</h6>
              <h2 class="font-weight-bolder mb-1">
                2,76k
              </h2>
              <!-- chart -->
              <vue-apex-charts height="70" :options="Ordersdata.chartOptions" :series="Ordersdata.series" />
            </b-card>
          </b-col>
          <b-col lg="6" md="3" cols="6">
            <b-card v-if="Profitdata" class="card-tiny-line-stats" body-class="pb-50">
              <h6>照明设备</h6>
              <h2 class="font-weight-bolder mb-1">
                6,24k
              </h2>
              <!-- chart -->
              <vue-apex-charts height="70" :options="Profitdata.chartOptions" :series="Profitdata.series" />
            </b-card>
          </b-col>
          <b-col lg="12" md="6">
            <b-card class="earnings-card">
              <b-row>
                <b-col cols="6">
                  <b-card-title class="mb-1">
                    设备数量
                  </b-card-title>
                  <div class="font-small-2">

                    智慧路灯
                  </div>
                  <h5 class="mb-1">
                    {{ leftData.room }}
                  </h5>
                  <b-card-text class="text-muted font-small-2">
                    <span>智能广播{{ rightData.room }}/控制器{{ getway }}</span>
                  </b-card-text>
                </b-col>
                <b-col cols="6">
                  <!-- chart -->
                  <vue-apex-charts height="120" :options="Earningsdata.chartOptions" :series="seriesData" />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <b-card no-body class="card-revenue-budget">
          <b-row class="mx-0">
            <b-col md="8" class="revenue-report-wrapper">
              <div class="d-sm-flex justify-content-between align-items-center mb-3">
                <h4 class="card-title mb-50 mb-sm-0">
                  设备运行情况
                </h4>
                <!-- <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-2">
                    <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
                    <span>在线</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
                    <span>离线</span>
                  </div>
                </div> -->
              </div>

              <!-- chart -->
              <vue-apex-charts id="revenue-report-chart" type="bar" height="230" :options="revenueReport.chartOptions"
                :series="revenueReport.series" />
            </b-col>

            <b-col md="4" class="budget-wrapper">
              <b-dropdown text="2023年" size="sm" class="budget-dropdown" variant="outline-primary">
                <!-- <b-dropdown-item v-for="year in revenue_report.years" :key="year">
                  {{ year.label }}
                </b-dropdown-item> -->
              </b-dropdown>

              <h2 class="mb-25">
                设备心跳情况
              </h2>
              <div class="d-flex justify-content-center">
                <span class="font-weight-bolder mr-25">总设备:</span>
                <span>{{ Number(rightData.room) + Number(leftData.room) + Number(getway) }}</span>
              </div>
              <vue-apex-charts id="budget-chart" type="line" height="80" :options="budgetChart.options"
                :series="budgetChart.series" />

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="go_url('/data-people')">
                查看详情
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>





  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BImg, BAvatar, BRow, BCol, BCardBody, BCardTitle, BDropdown, BDropdownItem, BButton,
  BCardHeader, BCardSubTitle, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

const $barColor = '#f3f3f3'
const $trackBgColor = '#EBEBEB'
const $earningsStrokeColor2 = '#4c43ff'
const $earningsStrokeColor3 = '#7367f0'

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BImg, BRow, BCol, VueApexCharts,
    BCardBody, BCardTitle,
    BDropdown, BDropdownItem, BButton, BCardHeader, BCardSubTitle, BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        name: '',
        saleToday: '',
        rolename: '',
        avatar: ''
      },
      leftData: {
        room: '',
        roomTitle: '',
        areaChartOptions: {
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2.5,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.9,
              opacityFrom: 0.5,
              opacityTo: 0.2,
              stops: [0, 80, 100],
            },
          },
          xaxis: {
            type: 'numeric',
            lines: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            labels: { show: false },
          },
          yaxis: [
            {
              y: 0,
              offsetX: 0,
              offsetY: 0,
              padding: {
                left: 0,
                right: 0,
              },
            },
          ],
          tooltip: {
            x: { show: false },
          },
          theme: {
            monochrome: {
              enabled: true,
              color: $themeColors.primary,
              shadeTo: 'light',
              shadeIntensity: 0.65,
            },
          },
        },
        chartData: [{
          name: '01',
          data: [90, 120, 55, 100, 125, 70, 88, 150],
        }
        ],
        color: '',
      },
      rightData: {
        room: '',
        roomTitle: '',
        areaChartOptions: {
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2.5,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.9,
              opacityFrom: 0.5,
              opacityTo: 0.2,
              stops: [0, 80, 100],
            },
          },
          xaxis: {
            type: 'numeric',
            lines: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            labels: { show: false },
          },
          yaxis: [
            {
              y: 0,
              offsetX: 0,
              offsetY: 0,
              padding: {
                left: 0,
                right: 0,
              },
            },
          ],
          tooltip: {
            x: { show: false },
          },
          theme: {
            monochrome: {
              enabled: true,
              color: $themeColors.warning,
              shadeTo: 'light',
              shadeIntensity: 0.65,
            },
          },
        },
        chartData: [{
          name: '02',
          data: [20, 40, 55, 100, 125, 20, 108, 130],
        }
        ],
        color: '',
      },
      Ordersdata: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
        series: [
          {
            name: '01',
            data: [10, 30, 20, 40, 10, 30],
          },
        ]
      },
      Profitdata: {
        chartOptions: {
          chart: {

            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
        series: [
          {
            name: '02',
            data: [10, 30, 20, 40, 10, 30],
          },
        ]
      },
      Earningsdata: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['控制器', '路灯', '广播'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: '路灯',
                    formatter() {
                      return '90%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      revenue_report: {
        budget: "56,800",
        price: "25,852",
        years: [
          {
            id: 1,
            label: '2023'
          },
        ]
      },
      revenueReport: {
        series: [
          {
            name: '',
            data: [44, 55, 57, 56, 61, 58, 63]
          }, {
            name: '',
            data: [76, 85, 101, 98, 87, 105, 91]
          }
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: { show: false },
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            // colors: ['transparent']
          },
          xaxis: {
            categories: ["08-24", "08-23", "08-22", "08-21", "08-20", "08-19", "08-18"],
            labels: {
              style: {
                colors: '#fff',
                fontSize: '0.86rem',
              },
            },
          },
          yaxis: {
            // title: {
            //   text: '$ (thousands)'
            // },
            labels: {
              style: {
                colors: '#fff',
                fontSize: '0.86rem',
              },
            },
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  val + "个设备在运行"
              }
            }
          },
          legend: {
            show: true,
            labels: {
              colors: '#fff',
            },
          },

        },
      },

      // budget chart
      budgetChart: {
        series: [
          {
            data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
          },
          {
            data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
          },
        ],
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },

      radarChart: {
        series: [
          {
            name: '未住宿',
            data: [41, 64, 81, 60, 42, 42, 33, 23],
          },
          {
            name: '已住宿',
            data: [65, 46, 42, 25, 58, 63, 76, 43],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            dropShadow: {
              enabled: false,
              blur: 8,
              left: 1,
              top: 1,
              opacity: 0.2,
            },
          },
          legend: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          yaxis: {
            show: false,
          },
          colors: [chartColors.donut.series1, chartColors.donut.series3],
          xaxis: {
            categories: ['Battery', 'Brand', 'Camera', 'Memory', 'Storage', 'Display', 'OS', 'Price'],
          },
          fill: {
            opacity: [1, 0.8],
          },
          stroke: {
            show: false,
            width: 0,
          },
          markers: {
            size: 0,
          },
          grid: {
            show: false,
          },
        },
      },
      getway: '',
      seriesData: []
    }
  },
  created() {
    let userData = JSON.parse(localStorage.getItem('userData'))
    this.data.name = userData.fullName
    this.data.rolename = userData.role
    this.data.avatar = userData.avatar
    this.get_data()
    this.get_info()
  },
  computed: {

  },
  mounted() {
    this.get_data()
    this.get_info()
  },

  methods: {
    go_url(path) {
      this.$router.replace(path)
    },
    get_info() {
      let dataObj = {
        act: "total",
      }
      this.$http.post('/svr/lamp_device.php', dataObj).then(res => {
        console.log(res, '123456');
        this.revenueReport.series = res.data.list
        this.revenueReport.chartOptions.xaxis.categories = res.data.date
        
      })
    },
    get_data() {
      let dataObj = {
        act: "dev_total",
      }
      this.$http.post('/svr/lamp_device.php', dataObj).then(res => {
        this.leftData.room = res.data.lamp_total
        this.rightData.room = res.data.boardcast_total
        this.getway = res.data.gateway_total
        this.seriesData = res.data.total
      })
    },

  }
}
</script>


<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.card {
  margin-bottom: 2rem !important;
}
::v-deep .apexcharts-tooltip {
    background: #000 !important;
    color: #000 !important;
  }
</style>
